import { Component, OnInit } from '@angular/core';
import { RequestService } from 'inservioweb/services/requestservice';
import * as APIMethodConfig from '../../common/apimethod';

@Component({
    selector: 'app-recoverycodes',
    templateUrl: './recoverycodes.component.html',
    styleUrls: ['./recoverycodes.component.sass'],
    standalone: false
})
export class RecoverycodesComponent implements OnInit {
  currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
  constructor( public requestService:RequestService) { }
  
  ngOnInit(): void {
    this.GetInservioversionList();

  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {

        this.VersionList = response.entity;
        if(this.VersionList.length>0)
        {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();          
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }

}
