    <!-- wrapper -->
<div class="loderBG" *ngIf="tableLoader"><img src="/images/loading.gif"></div>
<div class="wrapper col" style="height: 99.5vh;">
  <div class="row LoginBg">

    <div class="col lftImg"></div>
    <div class="col signTextBg">
      <div class="d-flex">
        <a href=""><img src="/images/logo1.svg"></a>
        <div class="signText">
          <div class="textBox">
            Our mission is to
            make lives easier for
            patients and doctors
          </div>
        </div>
        <div class="copyright-inner m-0">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. BE:{{version}} FE:21.0.2 </div>
      </div>
    </div>

    <!-- logIn-Rght -->
    <div class="col logIn-Rght">
      <div class="loginCont recoverCont">
        <form [formGroup]="formData">
          <h1>Recover Password</h1>
          <p>
            Enter your email address below<br>
            to reset password
          </p>

          <!-- <div class="form-group">
            <mat-form-field>
              <input matInput type="text" formControlName="username" placeholder="Username">
              <mat-error *ngIf="submitted && f.username.errors">Username is required</mat-error>
            </mat-form-field>
          </div> -->
          <div class="form-group">
            <label>Email</label>
            <input type="email" class="form-control" formControlName="username" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <mat-error *ngIf="submitted && f.username.errors">Username is required</mat-error>
          </div>
          <!-- <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="username" placeholder="Username">
            <mat-error *ngIf="submitted && f.username.errors">Username is required</mat-error>
          </mat-form-field> -->


          <button type="button" id="resetPassword" (click)="onSubmit()" class="common-btn">Email me recovery link</button>
          <div class="mt-5">
            <a [routerLink]="['/login']" class="d-inline-flex align-items-center text-decoration-none">
              <mat-icon>keyboard_arrow_left</mat-icon> Back to sign in
            </a>
          </div>

        </form>
        <div *ngIf="message" class="alert alert-danger">{{message}}</div>
      </div>
    </div>
    <!-- logIn-Rght -->

  </div>
</div>
<!-- wrapper -->
