<div class="wrapper col">
    <div class="row LoginBg">

      <div class="col lftImg"></div>
      <div class="col signTextBg">
        <div class="d-flex">
          <a href=""><img src="/images/logo1.svg"></a>
          <div class="signText">
            <div class="textBox">
              Our mission is to 
make lives easier for 
patients and doctors
            </div>
          </div>
          <div class="copyright-inner m-0">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. BE:{{version}} FE:21.0.2 </div>
        </div>
      </div>

      <!-- logIn-Rght -->
      <div class="col logIn-Rght">
        <a [routerLink]="['/login']" class="lgnHead"><img src="/images/lftGreenArw1.svg"></a>
        <div class="loginCont recoverCont">
          <h1>Recovery codes</h1>
          <p>If you lose your device and don't have the recovery codes you will lose access to your account.</p>
          <div class="loginForm">
            <div class="recoveryCode-bg">
              <div class="row">
                <div class="col">10a09077</div>
                <div class="col">f9445eec</div>
              </div>
              <div class="row">
                <div class="col">a03ab911</div>
                <div class="col">1c952c24</div>
              </div>
              <div class="row">
                <div class="col">5a005e27</div>
                <div class="col">e5657f4a</div>
              </div>
              <div class="row">
                <div class="col">f82484fc</div>
                <div class="col">c5cbe48b</div>
              </div>
              <div class="row">
                <div class="col">57181746</div>
                <div class="col">e282c6d5</div>
              </div>
            </div>
            <a [routerLink]="['/login']" class="btn common-btn">Login</a>
          </div>
        </div>
      </div>
      <!-- logIn-Rght -->

    </div>
  </div>