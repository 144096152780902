import { TwoFactorAuth } from './twofactorauth.model';
import { RequestService } from './../../services/requestservice';
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as APIMethodConfig from '../../common/apimethod';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, interval, fromEvent, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl, FormGroup, } from '@angular/forms';
import $ from "jquery";
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from "ngx-localstorage";
import * as CryptoJS from 'crypto-js';
import * as ConfigKey from '../../common/common';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-twofactorauth',
    templateUrl: './twofactorauth.component.html',
    styleUrls: ['./twofactorauth.component.sass'],
    standalone: false
})
export class TwofactorauthComponent implements OnInit {
  UserName: string = '';
  twoStepForm: any;
  dataArray: any;
  imageData: any;
  secretKey: string = '';
  verifyError: string = '';
  SubmitLoader: boolean = false;
  isverificationcode = true;
  VersionList = new Array<any>();
  version: string = '';
  currentyear: string = '';
  private unsubscriber: Subject<void> = new Subject<void>();
  constructor(public router: Router,
    public route: ActivatedRoute,
    public requestService: RequestService,
    private _el: ElementRef,
    private _sanitizer: DomSanitizer,
    private storageService: LocalStorageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.UserName = params['username'];
    });
    this.twoStepForm = new FormGroup({
      twoFactorCode: new FormControl('', [Validators.required]),
    });
    this.I2FAHelper();
    this.getSmartWayEnv();
    this.GetInservioversionList();
    var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
    if (Key != null) {
      history.pushState(null, '');
      fromEvent(window, 'popstate').pipe(
        takeUntil(this.unsubscriber)
      ).subscribe((_) => {
        history.pushState(null, '');
      });
    }
  }

  public I2FAHelper() {
    this.requestService.HttpGet<any>(APIMethodConfig.InservioAPIMethod.Account.Generate2FA + "/" + this.UserName, "").
      subscribe((response: any) => {
        this.onSuccess(response);
      }, (response: any) => this.onFailed(response));
  }

  public validateControl = (controlName: string) => {
    return this.twoStepForm.controls[controlName].invalid && this.twoStepForm.controls[controlName].touched
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.twoStepForm.controls[controlName].hasError(errorName)
  }

  public onSuccess(response: any) {
    this.dataArray = response.entity;
    this.imageData = 'data:image/png;base64,' + this.dataArray.qrCode;
    this.secretKey = this.dataArray.secretKey;
    this.twoStepForm.controls.twoFactorCode.setValue(this.dataArray.verificationcode);
    if (this.dataArray.isVerificationcode != null) {
      this.isverificationcode = this.dataArray.isVerificationcode;
    }
  }

  public onFailed(error: HttpErrorResponse) {
    const errorResponse: any = error.error;
    this.SubmitLoader = false;
    if (error.status > 0) {

    } else {

    }
  }

  public VerifyTOTP() {
    if (this.twoStepForm.value.twoFactorCode != undefined && this.twoStepForm.value.twoFactorCode != '') {
      this.SubmitLoader = true;
      let twoFactorDto: TwoFactorAuth = {
        UserEmail: this.UserName,
        secretkey: this.secretKey,
        token: this.twoStepForm.value.twoFactorCode,
        qrCode: ''
      }

      this.requestService.HttpPost<any>(APIMethodConfig.InservioAPIMethod.Account.Verify2FA, twoFactorDto).
        subscribe((response: any) => {
          if (response.entity.status == true) {
            var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
            if (Key != null) {
              this.GetSortPicker();
              let tokenDetail = response.entity.loginUserView;
              let tokenDetailencoded = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(tokenDetail), ConfigKey.Common.ENCRYPT.SECRETKEY).toString());
              this.storageService.set(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY, tokenDetailencoded);
              this.router.navigate(['/dashboard']);
              localStorage.setItem("dashboadlogin", "true");
            }
            else {
              this.router.navigate(['/recoverycodes']);
            }
          }
          else {
            if (response.returnMessage.length > 0) {
              this.verifyError = response.returnMessage[0];

            }
          }

          this.SubmitLoader = false;
        }, (response: any) => this.onFailed(response));
    }
  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {
        this.VersionList = response.entity;
        if (this.VersionList.length > 0) {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }

  public getSmartWayEnv() {
    this.requestService.HttpGetNoParam<smartwaysEnv>(APIMethodConfig.InservioAPIMethod.SmartWay.SmartwayEnvDetail).
      subscribe((response: smartwaysEnv) => {
        console.log(response);
        localStorage.setItem("token", response.smartwaY_TOKEN);
        localStorage.setItem("userId", response.smartwaY_USERID);
        localStorage.setItem("branchId", response.smartwaY_BRANCH.toString());
        localStorage.setItem("issandboxurl", response.smartwaY_ISSANBOXURL.toString());
        localStorage.setItem("smartwaysapi", response.smartwaY_SMARTWAYSAPI);
        localStorage.setItem("smartgridkey", response.smartgriD_KEY);
      }, (response: any) => console.log(response));
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public GetSortPicker() {
    this.requestService
      .HttpGetNoParam<any>(APIMethodConfig.InservioAPIMethod.Common.SortPickerList)
      .subscribe((response: any) => {
        let result = response.entity;
        if (result != null) {
          if (result.patientSortPicker != null) {
            let patientSortEncoded = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(result.patientSortPicker), ConfigKey.Common.ENCRYPT.SECRETKEY).toString());
            this.storageService.set("patient-column", patientSortEncoded);
          }

          if (result.orderSortPicker != null) {
            let orderSortEncoded = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(result.orderSortPicker), ConfigKey.Common.ENCRYPT.SECRETKEY).toString());
            this.storageService.set("order-column", orderSortEncoded);
          }
        }
      });
  }
}


class smartwaysEnv {
  public smartwaY_USERID: string = "";
  public smartwaY_TOKEN: string = "";
  public smartwaY_BRANCH: number = 0;
  public smartwaY_ISSANBOXURL: string = '';
  public smartwaY_SMARTWAYSAPI: string = '';
  public smartgriD_KEY: string = '';
}
