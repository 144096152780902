
 <!-- wrapper -->
 <div id="wrapper" class="wrapper col">
  <!-- Ins-headersec -->
  <div id="Ins-headersec">
    <nav class="navbar navbar-expand-lg">
      <!-- top1row -->
      <div class="col-12 top1row">
        <a class="navbar-brand"><img src="/images/logo.svg" class="toggleHide"></a>

        <div class="col tp-inRghtBg">
          <p> Our mission is to make lives easier for patients and doctors</p>
          <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-controls="collapseMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- tp-inRght -->
          <div class="tp-inRght">
            <li class="prfl dropdown">
              <a href="#" class="dropdown-toggle" role="button" id="" data-bs-toggle="dropdown" aria-expanded="false">
                <!-- <span><img src="/images/prflImg.png"></span> -->
                <!-- <ngx-avatars [style]="customStyleAvatar" size="25" [name]="fullName" ></ngx-avatars> -->
                <!-- <span class="badge rounded-pill text-white" style="font-size:small; font-weight: 400; background-color: #0d6856">
                  {{ fullName }}
                  </span> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#0d6856" class="bi bi-gear-fill icon-hover" viewBox="0 0 16 16">
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                  </svg>
                  <span> | </span>
                  <svg [routerLink]="['/login']" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#0d6856" class="bi bi-box-arrow-right icon-hover" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                    <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                  </svg>
              </a>

              <ul class="dropdown-menu" aria-labelledby="">
                <div class="HeaderSec">
                  <!-- <span><img src="/images/prflImg.png"></span> -->
                  <!-- <ngx-avatars [style]="customStyleAvatar" size="50" [name]="fullName" ></ngx-avatars> -->


                  <h3> {{fullName}} </h3>

                  <!-- <li><i><a style="font-size: 13px;cursor: pointer;" class="dropdown-item" (click)="resetpassword()">Reset Password</a></i></li> -->
                </div>


                  <li><a style="font-size: 13px; cursor: pointer;" class="dropdown-item" (click)="resetpassword()">Reset Password</a></li>

                  <li><a style="font-size: 13px; cursor: pointer;"  class="dropdown-item" [routerLink]="['/login']">Sign out</a></li>
          
              </ul>
            </li>
          </div>
          <!-- tp-inRght -->
        </div>
      </div>
      <!-- top1row -->
      <app-main-nav></app-main-nav>
    </nav>
  </div>
  <!-- Ins-headersec -->

  <!-- content -->
  <div class="content col">
    <!-- content-wrapper -->
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
    <!-- content-wrapper -->

  </div>
  <!-- content -->
</div>
<!-- wrapper -->
  <div class="copyright-inner to_bottom">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. BE:{{version}} FE:21.0.2</div>
