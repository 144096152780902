<div class="wrapper col" style="height: 99.5vh;">
    <div class="row LoginBg">

      <div class="col lftImg"></div>
      <div class="col signTextBg">
        <div class="d-flex">
          <a href=""><img src="/images/logo1.svg"></a>
          <div class="signText">
            <div class="textBox">
              Our mission is to make lives easier for patients and doctors
            </div>
          </div>
          <div class="copyright-inner">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. BE:{{version}} FE:21.0.2 </div>
        </div>
      </div>

      <!-- logIn-Rght -->
      <div class="col logIn-Rght">
        <a [routerLink]="['/login']" class="lgnHead"><img src="/images/lftGreenArw1.svg"></a>
        <div class="loginCont TwoFactorCont">
          <h1>Two-Factor Authentication</h1>
          <div class="qrBg" *ngIf="!isverificationcode">
            <!-- <img src="/images/lftGreenArw1.svg"> -->
            <img [src]="imageData">
          </div>
          <div class="qrContent">
            <form [formGroup]="twoStepForm">
            <div class="row verifyCode">
              <div class="col">
                <input type="text" id="twoFactorCode" autocomplete="off" formControlName="twoFactorCode"  class="form-control" placeholder="Verification Code">
              </div>
              <div class="col verifyBtn"> <button type="submit" *ngIf="!SubmitLoader" class="common-btn" (click)="VerifyTOTP()">Verify</button>
              <a *ngIf="SubmitLoader" class="btn common-btn">
                <span class="btnLoader"><i class="fa fa-spinner fa-spin"></i></span>
              </a></div>
              <em *ngIf="validateControl('twoFactorCode') && hasError('twoFactorCode', 'required')">The Code is required</em>
              <span style='color:crimson; font-family: Verdana, Geneva, Tahoma, sans-serif;'> {{ verifyError }}</span><br/>
            </div>
          </form>
         
            <ul *ngIf="!isverificationcode">
              <h5>To use an Authenticator app:</h5>
              <li>Download a two-factor authenticator app like Microsoft Authenticator for Windows Phone, Android and iOS or Google Authenticator for Android and iOS.</li>
              <li>Scan the QR Code or enter this key <span>{{secretKey}}</span>
                <!-- 42kp 2ow7 cuzf ukmy r4hn dhru 4y5p nvpn -->
                into your two factor authenticator app. Spaces and casing do not matter.
              </li>
              <li>Once you have scanned the QR Code your two factor authentication app will provide you with a unique code. Enter the code in the confirmation box below.</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- logIn-Rght -->

    </div>
