import { RequestService } from './../../services/requestservice';
import { Component, OnInit,HostListener, ElementRef } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import * as APIMethodConfig from '../../common/apimethod';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, interval } from 'rxjs';
import $ from "jquery";
@Component({
    selector: 'app-otpverification',
    templateUrl: './otpverification.component.html',
    styleUrls: ['./otpverification.component.sass'],
    standalone: false
})
export class OTPverificationComponent implements OnInit {
UserName:string='';
unmeshow:string='';
successmessage:string='';
timer:number=120;
timerval:string='';
currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
timerOn:boolean=true;
curDate:Date=new Date();
expDate:Date=new Date();
private subscription: Subscription;
public dateNow = new Date();
public dDay = new Date('Jun 01 2021 00:00:00');
milliSecondsInASecond = 1000;
hoursInADay = 24;
minutesInAnHour = 60;
SecondsInAMinute  = 60;

public timeDifference:any;
public secondsToDday:any;
public minutesToDday:any;
public hoursToDday:any;
public daysToDday:any;


private getTimeDifference () {
    var timediff=this.expDate.getTime()-new Date().getTime(); //this.timer;
    if(timediff>=0)
    {
    this.allocateTimeUnits(timediff);
    }
}
private allocateTimeUnits (timeDifference:any) {
    this.minutesToDday = Math.floor((timeDifference % (this.milliSecondsInASecond * this.minutesInAnHour *this.SecondsInAMinute))/ (this.milliSecondsInASecond * this.minutesInAnHour));
  this.secondsToDday = Math.floor((timeDifference % (this.milliSecondsInASecond * this.minutesInAnHour))/(this.milliSecondsInASecond));
    this.timer=this.timer-1;
}

public GetInservioversionList() {
  this.requestService
    .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
    .subscribe((response: any) => {

      this.VersionList = response.entity;
      if(this.VersionList.length>0)
      {
        this.version = this.VersionList[0].versionTag;
        var currentTime = new Date();
        this.currentyear = currentTime.getFullYear().toString();
      }
    });
}
  constructor(public router: Router,
    public route: ActivatedRoute,
    public requestService:RequestService,
    private _el: ElementRef
   ) {
      this.subscription=new Subscription;
     }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.UserName = params['username'];
      this.dDay=params['expdt'];
    });
    if (this.UserName == null || this.UserName=='') {
      this.router.navigate(['/login']);
     }
     else
     {
       let optsplit=this.UserName.split("@");
       let lasty=optsplit[1];
       let firsty=optsplit[0];
       let lastthre=firsty.substring(firsty.length - 3);
       let x=""; let y=0;
       while(y<firsty.length-3){y++; x=x+"x";}
       this.unmeshow=x+lastthre+"@"+lasty;
     }
     this.subscription = interval(1000)
     .subscribe(x => {this.expDate=new Date(this.dDay);
      if(this.expDate<new Date())
      {this.getTimeDifference();
        this.timerOn=true; }
      else { this.timerOn=false; }
       });
       this.GetInservioversionList();

  }

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
        e.preventDefault();
        let nextControl: any = e.srcElement.nextElementSibling;
       // Searching for next similar control to set it focus
        while (true)
        {
            if (nextControl)
            {
                if (nextControl.type === e.srcElement.type)
                {
                    nextControl.focus();
                    return;
                }
                else
                {
                    nextControl = nextControl.nextElementSibling;
                }
            }
            else
            {
                return;
            }
        }
    }
}

public VerfyOTP(){

  this.requestService.HttpPOSTPassword<string>(APIMethodConfig.InservioAPIMethod.Account.ForgetPassword, this.UserName).
  subscribe((response: any) => {
    this.successmessage="OTP has beed resended Again";
    var newexp=response.entity;
    if(newexp !=null)
    {
    this.dDay=new Date(newexp);
    }
  }, (response:any) => this.addOrUpdateFailed(response));


  //this.router.navigate(['/updatepassword']);
}
public ResendOTP(){
  this.requestService.HttpPOSTPassword<string>(APIMethodConfig.InservioAPIMethod.Account.ForgetPassword, this.UserName).
      subscribe((response: any) => {
        this.successmessage="OTP has beed resended Again";
        var newexp=response.entity;
        if(newexp !=null)
        {
        this.dDay=new Date(newexp);
        }
      }, (response:any) => this.addOrUpdateFailed(response));
    }
    private addOrUpdateFailed(error: HttpErrorResponse) {
      let errorResponse: any = error.error;
      //this.alertService.stopProgressBar();
      if (error.status > 0) {
        //this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
      } else {
        //this.alertService.ShowErrorMessage(error.message);
      }
    }

}
